<template>
  <div style="margin: 25px 20px 0 20px">
    <h1>{{ $t("message.title-items-marketplace") }}</h1>

    <div class="my-4">
      <v-form>
        <v-row no-gutters>
          <v-col cols="12" md="10">
            <v-row>
              <v-col cols="12" md="1" xs="2">
                <span>{{ $t("message.shop") }}</span>
              </v-col>
              <v-col cols="12" md="2" xs="2">
                <v-autocomplete
                  :items="shopList"
                  v-model="store"
                  outlined
                  @change="changeShop"
                  required
                  item-text="store_name"
                  item-value="id"
                  hide-details="true"
                  
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="1" xs="2" class="ml-4">
                <span>{{ $t("message.marketplace") }}</span>
              </v-col>
              <v-col cols="12" md="2" xs="2">
                <v-select
                  
                  :items="marketplaceByStore"
                  v-model="marketplaceId"
                  outlined
                  @change="getMarketplace"
                  item-text="marketplace_name"
                  item-value="id"
                  hide-details="true"
                  :no-data-text="getMsgMarketplaceSelected ? 'データが見つかりません' : 'データのロード . . .'"
                >
                <template v-slot:prepend-inner>
                  <div v-if="isLoadingMarketplaceList" class="d-flex justify-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="27"
                    ></v-progress-circular>
                  </div>
                </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="2" style="margin-top: 20px">
            <div class="d-flex justify-end">
              <v-btn
                rounded
                dark
                color="primary"
                type="button"
                @click="addListItemsMarketplace"
              >
                {{ $t("message.btn-save") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div class="my-5">
      <div class="marketplace-name">
        <h4 class="title-marketplace">{{ this.marketplaceName }}</h4>
      </div>

      <div v-if="itemsMarketplace.length !== 0" class="grid-card">
        <!-- <div v-for="index in 50" :key="index"> -->
        <div v-for="(cardItem, idx) in itemsMarketplace" :key="idx">
          <div
            v-if="
              cardItem.item_id != null &&
                cardItem.ordered_at != -1 &&
                idx + 1 == cardItem.ordered_at
            "
            :class="cardItem.is_active == 0 ? 'card-inActive' : 'class-card'"
            active-class="isActive"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  width="75px"
                  height="60px"
                  outlined
                  style="border: 1px solid black"
                 v-bind="attrs"
                 v-on="on"
                  v-bind:class="{ isActive: cardItem.item_id === activeId }"
                  @click="
                    moveValueItems(cardItem.ordered_at, cardItem.item_id);
                    hasImage = true;
                  "
                >
                  <v-img
                    :src="cardItem.image_url"
                    :lazy-src="cardItem.image_url"
                    height="58px"
                    contain
                  >
                    <div class="icon-class">
                      <v-icon
                        class="icon-close"
                        style="cursor: pointer;"
                        @click.stop="deleteItemMarketplace(cardItem.ordered_at)"
                      >
                        mdi-close-box
                      </v-icon>
                    </div>
                  </v-img>
                  <div class="class-item-name">
                    <!-- <span class="item-name">{{ cardItem.item_name }}</span> -->
                  </div>
                </v-card>
              </template>
              <div>
                <span>{{ cardItem.item_name }}</span>
              </div>
            </v-tooltip>
          </div>

          <div
            v-else-if="
              cardItem.item_id == null && cardItem.ordered_at == idx + 1
            "
          >
            <v-card
              width="75px"
              height="60px"
              outlined
              style="border: 1px solid black"
              v-bind:class="isSwap ? 'class-card' : ''"
              @click="
                isSwap
                  ? moveValueItems(cardItem.ordered_at, cardItem.item_id)
                  : getKeyCards(idx + 1);
                hasImage = false;
              "
            >
              <div class="content-card" v-bind:style="{ display: isDisplay }">
                <v-icon class="icon-class">
                  mdi-plus
                </v-icon>
              </div>
            </v-card>
          </div>
        </div>
        <v-row justify="center">
          <v-dialog v-model="dialog" max-width="750" persistent>
            <v-card>
              <!-- <v-container> -->
              <v-form @submit.prevent="submitAddItemsMarketplace">
                <v-row no-gutters>
                  <v-col cols="12" md="7">
                    <v-card-title class="text-h5">
                      {{ $t("message.title-add-items-marketplace") }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" md="5">
                    <div class="grid-btn-submit">
                      <div class="my-4 mx-4">
                        <v-btn color="grey" @click="dialog = false">
                          {{ $t("message.cancel") }}
                        </v-btn>
                      </div>
                      <div class="my-4">
                        <v-btn
                          color="primary"
                          @click="dialog = false"
                          type="submit"
                        >
                          {{ $t("message.submit-button") }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-card-text>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12" md="2" xs="2">
                        <span>{{ $t("message.label-itemlist") }}</span>
                      </v-col>
                      <v-col cols="12" md="10" xs="2">
                        <v-autocomplete
                          v-model="addForm.itemMarketplaceId"
                          :items="itemListDropdown"
                          item-value="id"
                          item-text="item_name"
                          outlined
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
              <!-- </v-container> -->
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div v-else>
        <h1 class="text-center my-4" style="color: gray;">
          {{ $t("message.no-data") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemMarketplace",
  data() {
    return {
      dialog: false,
      store: "",
      marketplaceId: "",
      marketplaceName: "",
      addForm: {
        itemMarketplaceId: "",
        itemMarketplaceName: "",
        itemMarketplaceImage: "",
      },
      listItemsMarketplace: null,
      isLoadingMarketplaceList: false,
      getMsgMarketplaceSelected: false,
      listSelectMarketplace: [],
      tempItems: [],
      tempKey: null,
      deletedItem: [],
      hasImage: false,
      isSwap: false,
      activeId: 0,
      isDisplay: "flex",
    };
  },
  watch: {
    hasImage(val) {
      if (val) {
        this.isSwap = true;
      } else {
        this.isSwap = false;
      }
    },
  },
  methods: {
    getKeyCards(key) {
      this.dialog = true;
      this.tempKey = key;
    },
    moveValueItems(ordered_at, item_id) {
      this.activeId = item_id;
      this.isSwap = true;

      this.isDisplay = "none";

      if (this.tempItems.length >= 2) {
        this.tempItems = [];
      }
      this.tempItems.push(ordered_at);

      if (this.tempItems.length == 2) {
        let items = this.$store.state.marketplace_module.itemsMarketplace;
        var fromIndex = items.findIndex(
          (obj) => obj.ordered_at == this.tempItems[0]
        );
        var toIndex = items.findIndex(
          (obj) => obj.ordered_at == this.tempItems[1]
        );
        var orderFrom = items[fromIndex].ordered_at;
        var orderTo = items[toIndex].ordered_at;
        var temp = items[fromIndex];
        items[fromIndex] = items[toIndex];
        items[toIndex] = temp;
        items[toIndex].ordered_at = orderTo;
        items[fromIndex].ordered_at = orderFrom;

        this.activeId = 0;
        this.isDisplay = "flex";
        this.isSwap = false;

        return this.$store.dispatch("marketplace_module/swapItems", items);
      }
    },
    deleteItemMarketplace(ordered_at) {
      let items = this.$store.state.marketplace_module.itemsMarketplace;
      var index = items
        .map((x) => {
          return x.ordered_at;
        })
        .indexOf(ordered_at);
      var temp = {
        item_id: items[index].item_id,
        item_name: items[index].item_name,
        image_url: items[index].image_url,
        ordered_at: -1,
      };
      this.deletedItem.push(temp);
      items[index].item_id = null;
      items[index].item_name = null;
      items[index].image_url = null;
      items[index].ordered_at = index + 1;

      return this.$store.dispatch("marketplace_module/deleteStateItem", items);
    },
    addListItemsMarketplace() {
      let items = this.$store.state.marketplace_module.itemsMarketplace;
      var itemsV2 = [...this.deletedItem, ...items];
      var payload = itemsV2
        .filter((el) => {
          return el.item_id != null;
        })
        .map((val) => {
          return {
            item_id: val.item_id,
            marketplace_id: this.marketplaceId,
            ordered_at: val.ordered_at,
          };
        });
      return this.$store.dispatch("marketplace_module/addItemsMarketplace", {
        marketplace_id: this.marketplaceId,
        items: payload,
      });
    },
    submitAddItemsMarketplace() {
      let listData = this.$store.state.itemList_module.itemCodeList;
      let filterData = listData.find(
        (val) => val.id == this.addForm.itemMarketplaceId
      );
      let payload = {
        item_id: filterData.id,
        marketplace_id: this.addForm.itemMarketplaceId,
        image_url: filterData.image,
        item_name: filterData.item_name,
        ordered_at: this.tempKey,
      };
      this.$store.dispatch(
        "marketplace_module/addItemStateMarketplace",
        payload
      );
      // this.addForm.itemMarketplaceId = ""
    },
    async changeShop(event) {
      this.isLoadingMarketplaceList = true
      this.getMsgMarketplaceSelected = true
      let res = await this.$store.dispatch(
        "marketplace_module/marketplaceByStore",
        this.store
      )

      if(res.status == 200) {
        this.isLoadingMarketplaceList = false
        this.getMsgMarketplaceSelected = false
      }
        this.isLoadingMarketplaceList = false
      
        this.marketplaceId = "";
        this.marketplaceName = "";
      
    },
    async getMarketplace(event) {
      let marketplace = this.$store.state.marketplace_module.marketplaceByStore;
      let filterId = marketplace.find((el) => el.id == this.marketplaceId);
      this.marketplaceName = filterId.marketplace_name;
      await this.dispatchItemsMarketplace(this.marketplaceId);
    },
    fetchShopList() {      
      return this.$store.dispatch("shop_module/fetchShopAll");
    },
    async dispatchItemsMarketplace(marketplaceId) {
      if (marketplaceId !== undefined) {
        await this.$store.dispatch(
          "marketplace_module/itemsMarketplace",
          marketplaceId
        );
      }
    },
    fetchItemListDropdown() {
      return this.$store.dispatch("itemList_module/fetchItemCode");
    },
  },
  mounted() {
    this.$store.dispatch("marketplace_module/clearItemsMarketplace");
  },
  created() {
    this.fetchShopList();
    this.dispatchItemsMarketplace();
    this.fetchItemListDropdown();
  },
  computed: {
    itemListDropdown() {
      var dd = this.$store.state.itemList_module.itemCodeList;
      var item_marketplace = this.$store.state.marketplace_module
        .itemsMarketplace;

      var ids = item_marketplace.map((x) => {
        return x.item_id;
      });
      var res = dd.filter(({ id }) => !ids.includes(id));
      return res;
    },
    shopList() {
      let shopDefault = this.$store.state.shop_module.shopAllResult;
      return shopDefault;
    },
    marketplaceByStore() {      
      return this.$store.state.marketplace_module.marketplaceByStore;
    },
    itemsMarketplace() {
      let items = this.$store.state.marketplace_module.itemsMarketplace;
      this.listItemsMarketplace = items.map((val) => {
        return val.item_id;
      });
      return this.$store.state.marketplace_module.itemsMarketplace;
    },
  },
};
</script>

<style>
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.isActive {
  border: 4px solid #3949ab !important;
}
.grid-btn-submit {
  /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5px, auto)); */
  display: flex;
  justify-content: center;
}
.marketplace-name {
  width: 100%;
  height: 4rem;
  background-color: #3949ab;
  display: flex;
  align-items: center;
}
.title-marketplace {
  margin: 0 20px;
  color: white;
}
.grid-card {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(8rem, auto)); */
  grid-template-columns: repeat(20, 1fr);
  gap: 0.5rem;
  /* max-width: auto;
  max-height: auto; */
  width: auto;
  height: 700px;
  overflow-x: scroll;
  overflow-y: hidden;
  /* margin: 0 auto; */
  padding: 8px 20px 0 20px;
  /* margin: 20px; */
}
.content-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 17px 0;
}

.icon-close:hover {
  color: red;
}
.class-card:hover {
  /* transition: box-shadow .3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  /* box-shadow: 0 30px 20px rgb(0 0 0 / 20%); */
  box-shadow: 0 10px 20px grey;
}
.card-inActive {
  /* border: 5px solid red; */
  /* background-color: rgb(16, 11, 11); */
  opacity: 0.2;
}

.class-item-name {
  height: 50px;
  padding: 10px;
  text-align: center;
}
.item-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.icon-class {
  float: right;
}
@media screen and (max-width: 1020px) {
  .grid-card {
    width: 250px;
  }
}
</style>
